import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {APP_CONFIG} from '@frontend/app-config';

@Injectable({
  providedIn: "root"
})
export class PaymentGatwayService {
  ApiUrl: string;
  B2CUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}`;
    this.B2CUrl = `${this.appConfig.b2cUrl}`;
  }

  createPaymentIntent(body: any) {
    const headers = new HttpHeaders({'Skip-auth': 'true'});
    return this.http.post(`${this.ApiUrl}public/create-payment-intent`, body, {headers});
  }

  getConversationRates() {
    return this.http.get(`${this.B2CUrl}public/getCurrencyRates`);
  }

  paymentToken(body: any) {
    // if (body.role === 'artist') {
    //   return this.http.post(`${this.ApiUrl}tokens/buy-token`, body);
    // } else {
      return this.http.post(`${this.ApiUrl}tokens/buy-token-for-listener`, body);
    // }
  }

  paymentIntent(body: any) {
    console.log('=---0-0-0-', body)
    return this.http.post(`${this.ApiUrl}tokens/payment-intent`, body);
  }
}
